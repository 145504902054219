import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Field, Formik, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik";
import { forgotPasswordFormValidationSchema } from "validationSchemas";

const Login = () => {

  const handleReset = (values) => {
    console.log(values);
  }

  return (
    <>
      <Col lg="5" md="7">
        <Row className="mt-3">
          <Col className="text-center pb-3">
            <img
              style={{ width: 200 }}
              src={require("../../assets/img/brand/robin-logo.png")}
              alt="Robin"
            />
          </Col>
        </Row>
        <Card className="bg-secondary border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {/* <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div> */}
            <Formik
              initialValues={{email: ""}}
              onSubmit={handleReset}
              validationSchema={forgotPasswordFormValidationSchema}
            >
              {({ isValid }) => (
              <Form>
                <Field
                  placeholder="Email"
                  type="text"
                  name="email"
                  autoComplete="email"
                  component={ReactstrapInput}
                />
                <div className="text-center">
                  <Button className="mt-4" color="primary" disabled={!isValid}>
                    Continue
                  </Button>
                </div>
              </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col className="text-center" xs="12">
            <Link
              to="../login"
            >
              <small>Login</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
