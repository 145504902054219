
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Field, Formik, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik";
import { registrationFormValidationSchema } from "validationSchemas";
import LoginService from "../../services/LoginService";

const ApiService = new LoginService();

const Register = () => {
  const [terms, setTerms] = useState(false)
  const [success, setSuccess] = useState(false)
  //const [errors, setErrors] = useState(null)

  const handleSigup = (values, { setStatus }) => {
    ApiService._postRegister(
      {
        ...values,
        url: "www"
      },
      () => {
        setSuccess(true)
        setStatus("")
      },
      (error) => {
        setStatus(error.response.data.error)
        console.log(error.response.data)
      }
    )
  }

  return (
    <>
      <Col lg="6" md="8">
        <Row className="mt-3">
          <Col className="text-center pb-3">
            <img
              style={{ width: 200 }}
              src={require("../../assets/img/brand/robin-logo.png")}
              alt="Robin"
            />
          </Col>
        </Row>
        <Card className="bg-secondary border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {
            success ? 
            <p className="lead text-center">Successfully registred. Go to <Link to="../login">Login</Link>?</p>
            : 
            <Formik
              initialValues={{name: "", email: "", password: ""}}
              onSubmit={handleSigup}
              validationSchema={registrationFormValidationSchema}
            >            
            {({ isValid, status }) => (
            <Form>
              {status && (
                <p className="error" style={{ color: "red"}}>{status}</p>
              )}
              <Field
                    placeholder="Full Name"
                    type="text"
                    name="name"
                    autoComplete="new-name"
                    component={ReactstrapInput}
              />
              <Field
                    placeholder="Email"
                    type="text"
                    name="email"
                    autoComplete="new-email"
                    component={ReactstrapInput}
              />
              <Field
                    placeholder="Password"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    component={ReactstrapInput}
              />
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      value={terms}
                      onChange={(e) => setTerms(e.target.checked)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="https://www.atidot.com/privacy-notice/" target="_blank" rel="noreferrer">
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button className="mt-4" color="primary" disabled={!isValid || !terms}>
                  Create account
                </Button>
              </div>
            </Form>
            )}
            </Formik>
            }
          </CardBody>
        </Card>
        
        <Row className="mt-3">
          <Col className="text-center" xs="12">
            <Link
              to="../login"
            >
              <small>Login</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Register;
