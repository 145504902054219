import { useState } from "react";
import {
  Button,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row
} from "reactstrap";

const Agent = () => {
  const [ data, setData ] = useState({
    url: localStorage.getItem("atidot-back-office-url") ? localStorage.getItem("atidot-back-office-url") : "",
    prompt: localStorage.getItem("atidot-back-office-prompt") ? localStorage.getItem("atidot-back-office-prompt") : "",
  })

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleStoreUrl = () => {
    localStorage.setItem("atidot-back-office-url", data.url)
  }

  const handleStorePrompt = () => {
    localStorage.setItem("atidot-back-office-prompt", data.prompt)
  }

  return (
    <>
      {/* Page content */}

      <Container className="pt-6" fluid>
        <Row className="px-3">
          <InputGroup className="mb-4">
            <InputGroupText>
              Store Url
            </InputGroupText>
            <Input name="url" value={data.url} onChange={handleChange} className="px-2"/>
            <Button color="primary" onClick={handleStoreUrl}>Apply</Button>
          </InputGroup>
          <h4>Store Prompt</h4>
          <InputGroup className="mb-4">
            <textarea name="prompt" className="px-2 form-control" style={{ width: "100%", minHeight: 120 }} value={data.prompt} onChange={handleChange}/>
          </InputGroup>
          <InputGroup className="mb-4">
            <Button color="primary"onClick={handleStorePrompt}>Save</Button>
          </InputGroup>
        </Row>
      </Container>
    </>
  );
};

export default Agent;
