import {
  Container
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

const Analytics = () => {
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
      </Container>
    </>
  );
};

export default Analytics;
