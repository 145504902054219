
import * as yup from 'yup';

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export const loginFormValidationSchema = yup.object().shape({
  email: yup
      .string()
      .trim()
      .email('Enter valid e-mail')
      .matches(emailRegex, 'Enter valid e-mail')
      .required('Email is required'),
  password: yup.string().trim().required('Password is required').min(4, "Password should be at least 4 characters long"),
});

export const registrationFormValidationSchema = yup.object().shape({
  name: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, 'Enter valid name')
      .required('Full name is required'),
  email: yup
      .string()
      .trim()
      .email('Enter valid e-mail')
      .matches(emailRegex, 'Enter valid e-mail')
      .required('Email is required'),
  password: yup.string().trim().required('Password is required').min(4, "Password should be at least 4 characters long")
});

export const forgotPasswordFormValidationSchema = yup.object().shape({
    email: yup
        .string()
        .trim()
        .email('Enter valid e-mail')
        .matches(emailRegex, 'Enter valid e-mail')
        .required('Email is required')
});