import { Field, Formik, Form } from "formik";
import { decryptData } from "helpers";
import { encryptData } from "helpers";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { ReactstrapInput } from "reactstrap-formik";
import LoginService from "../../services/LoginService";
import { loginFormValidationSchema } from "validationSchemas";

const ApiService = new LoginService();

const Login = () => {
  const navigate = useNavigate()
  const [rememberPassword, setRememberPassword] = useState(true)
  
  const handleRememberMe = () => {
    setRememberPassword(!rememberPassword)
  }

  const handleLogin = (values) => {
    ApiService._postLogin(values, () => {
      if (rememberPassword) {
        localStorage.setItem("atidot-back-office-creds", JSON.stringify({ ...values, password: encryptData(values.password)}))
      }
      navigate("/admin/index")
    })
  }

  const getInitialValues = () => {
    const credentials = localStorage.getItem("atidot-back-office-creds")
    if (credentials) {
      const parse = JSON.parse(credentials) 
      return { ...parse, password: decryptData(parse.password)}
    }
    return {
      email: "",
      password: ""
    }
  }

  return (
    <>
      <Col lg="5" md="7">
        <Row className="mt-3">
          <Col className="text-center pb-3">
            <img
              style={{ width: 200 }}
              src={require("../../assets/img/brand/robin-logo.png")}
              alt="Robin"
            />
          </Col>
        </Row>
        <Card className="bg-secondary border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {/* <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div> */}
            <Formik
              initialValues={getInitialValues()}
              onSubmit={handleLogin}
              validationSchema={loginFormValidationSchema}
            >
              {({ isValid, values }) => (
              <Form>
                <Field
                  placeholder="Email"
                  type="text"
                  name="email"
                  value={values["email"]}
                  autoComplete="email"
                  component={ReactstrapInput}
                />
                <Field
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={values["password"]}
                  autoComplete="password"
                  component={ReactstrapInput}
                />
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                    checked={rememberPassword}
                    onChange={handleRememberMe}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button className="mt-4" color="primary" disabled={!isValid}>
                    Sign in
                  </Button>
                </div>
              </Form>
              )}
            </Formik>

          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link
              to="../reset"
            >
              <small>Forgot password?</small>
            </Link>
          </Col>
          <Col className="text-right" xs="6">
            <Link
              to="../register"
            >
              <small>Create new account</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
