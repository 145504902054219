import {
  Button,
  Container,
  InputGroup,
  Row,
  Tooltip
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useState } from "react";

const CopyButton = (props) => {
  const defaultTooltip = "Copy to clipboard"
  const [tooltipText, setTooltipText] = useState(defaultTooltip)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleCopySnippet = () => {
    navigator.clipboard.writeText(props.clipboard)
    setTooltipText("Copied!")
  }

  const handleCopySnippetButtonOut = () => {
    setTooltipText(defaultTooltip)
  }
  
  return (
  <>
    <Button {...props} onClick={handleCopySnippet} onMouseOut={handleCopySnippetButtonOut}>Copy</Button>
    <Tooltip
      placement="top"
      target={props.id}
      isOpen={tooltipOpen}
      toggle={toggle}
    >
      {tooltipText}
    </Tooltip>
  </>
)
}

const meta = `<script src="https://unpkg.com/react@18/umd/react.production.min.js" defer></script>
<script src="https://unpkg.com/react-dom@18/umd/react-dom.production.min.js" defer></script>
<script src="https://agent.getrobin.store/main.js" defer></script>`

const getSnippet = (token) => `<div id="widget-container"></div>
<script>
        window.onload = function() {
          window.RealTimeAssistant.renderWidget(
            document.getElementById('widget-container'),
            { access_token: '${token ? token : "your_token"}' }
          );
        }
</script>`

const Settings = () => {
  const apiKey = localStorage.getItem("robin_public_access_token") ? localStorage.getItem("robin_public_access_token") : "your_token"
  const snippet = getSnippet(apiKey)

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="px-3 pb-4">
          <h4>{"Place this code inside the <head> tag of your webstore"}</h4>
          <InputGroup className="mb-4" size="sm">
            <textarea className="form-control" readOnly value={meta} style={{ height: 62, overflowY: "hidden" }}/>
          </InputGroup>

          <CopyButton id="copy-meta-btn" clipboard={meta}/>
        </Row>
        <Row className="px-3">
          <h4>{"Place this code inside the <body> tag of your webstore"}</h4>
          <InputGroup className="mb-4" size="sm">
            <textarea className="form-control" readOnly value={snippet} style={{ height: 170, overflowY: "hidden" }}/>
          </InputGroup>

          <CopyButton id="copy-snippet-btn" clipboard={snippet}/>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
