import {
  Col,
  Container,
  Row
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import SessionsTable from "components/Tables/SessionsTable/SessionsTable";
import { useEffect, useState } from "react";
import SessionsService from "../../services/SessionsService";

const ApiService = new SessionsService()

const headers = [
  "Date",
  "Record",
  "Name"
]

const Sessions = () => {
  const [ data, setData ] = useState(null);

  useEffect(() => {
    try {    
      const id = parseInt(localStorage.getItem("atidot-back-office-user"))
      ApiService._postRecords({"user_id" : id}, 
        (response) => {
          const res = response.data
          ?.filter(({ filename }) => filename && /[.]mp3$/gm.test(filename))
          ?.map((item) => ({
            ...item,
            audio: new Audio(process.env.REACT_APP_FILES+item.filename)
          }))
          setData(res)
        }
      )
    } catch (error) {
      console.log(error);
    }
  }, [])

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col xl="12">
            <SessionsTable headers={headers} data={data}/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Sessions;
