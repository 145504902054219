import Index from "views/Index.js";
import Register from "views/pages/Register.js";
import Login from "views/pages/Login.js";
import Reset from "views/pages/Reset.js";
import Agent from "views/pages/Agent.js";
import Analytics from "views/pages/Analytics.js";
import Settings from "views/pages/Settings";
import Sessions from "views/pages/Sessions";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-black",
    component: <Index />,
    layout: "/admin",
  },
  {
    path: "/agent",
    name: "Agent",
    icon: "ni ni-single-02 text-black",
    component: <Agent />,
    layout: "/admin",
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: "ni ni-chart-pie-35 text-black",
    component: <Analytics />,
    layout: "/admin",
  },
  {
    path: "/sessions",
    name: "Sessions",
    icon: "ni ni-collection text-black",
    component: <Sessions />,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-bullet-list-67 text-black",
    component: <Settings />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-black",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-black",
    component: <Register />,
    layout: "/auth",
  },
  {
    path: "/reset",
    name: "Reset",
    icon: "ni ni-circle-08 text-black",
    component: <Reset />,
    layout: "/auth",
  },
];
export default routes;
