import React from "react";
import useAudio from "hooks/useAudio";
import { Button } from "reactstrap";
import moment from "moment";
import { formatTime } from "helpers";

const SessionsTableRow = ({id, audio, filename, created_at }) => {
    const [ status, progress, duration, toggle, update ] = useAudio(audio)

    return (
      <tr key={`session-table-row-${id}`}>
      <td key={`session-table-cell-date-${id}`}>
        {moment(created_at).format("MM/DD/YYYY h:mm:ss")}
      </td>
        <th key={`session-table-cell-play-${id}`}>
            <div style={{ display: "flex", alignItems: "center"}}>
              <Button type="button" className="btn" onClick={toggle}>
                  {status === "playing" ? <i class="fa fa-pause" aria-hidden="true"/> : <i class="fa fa-play" aria-hidden="true"/>}
              </Button>
              <input type="range" value={progress} max={duration} onChange={update} className="form-range" color="dark"/>
              <p style={{ margin: 0, marginLeft: 5 }}>{formatTime(progress)}/{formatTime(duration)}</p>
            </div>
        </th>
        <td key={`session-table-cell-name-${id}`}><i className="fa fa-file" /> {filename}</td>
      </tr>
    )
}

export default SessionsTableRow;